import React from "react";
import { Box, Typography, Container, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Navigation from "../compponents/Navigation";

const About = () => {
  const { t } = useTranslation();

  return (
    <Box backgroundColor="#E8EAF6" py={4} minHeight="100vh">
      <Container maxWidth="lg">
        {/* Navigation at the top if you want */}
        <Navigation />

        {/* ============= INTRO SECTION ============= */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            mt: 8,
            mb: 8,
          }}
        >
          {/* Left: Image */}
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/valeriaphoto.jpg`}
            alt="Valeria"
            sx={{
              width: { xs: "100%", md: "40%" },
              maxHeight: 500,
              borderRadius: "8px",
              objectFit: "cover",
              mb: { xs: 2, md: 0 },
            }}
          />

          {/* Right: Title, Subtitle, Button */}
          <Box
            sx={{
              flex: 1,
              ml: { md: 4 },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography variant="h4" gutterBottom>
              {t("about.introTitle")}
            </Typography>

            {t("about.introSubtitle", { returnObjects: true }).map(
              (paragraph, index) => (
                <Typography key={index} variant="body1" paragraph>
                  {paragraph}
                </Typography>
              )
            )}
            <Button variant="contained" color="primary">
              {t("about.introButton")}
            </Button>
          </Box>
        </Box>

        {/* <Box sx={{ mb: 8 }}>
          <Typography variant="h5" gutterBottom>
            {t("about.missionTitle")}
          </Typography>

          <Typography variant="body1" paragraph>
            {t("about.missionSubtitle")}
          </Typography>

          <Grid container spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                {t("about.dreamItTitle")}
              </Typography>
              <Typography variant="body1">
                {t("about.dreamItDescription")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                {t("about.buildItTitle")}
              </Typography>
              <Typography variant="body1">
                {t("about.buildItDescription")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 10 }}>
          <Typography variant="h5" gutterBottom>
            {t("about.sectionTitle")}
          </Typography>
          <Typography variant="body1" paragraph>
            {t("about.description1")}
          </Typography>
          <Typography variant="body1" paragraph>
            {t("about.description2")}
          </Typography>
          <Typography variant="body1" paragraph>
            {t("about.description3")}
          </Typography>
          <Typography variant="body1" paragraph>
            {t("about.description4")}
          </Typography>
          <Typography variant="body1" paragraph>
            {t("about.description5")}
          </Typography>
          <Typography variant="body1" paragraph>
            {t("about.description6")}
          </Typography>
        </Box> */}
      </Container>
    </Box>
  );
};

export default About;

import { Container, Button, Grid2, Typography, Box } from "@mui/material";
import Navigation from "../compponents/Navigation";
import EnergyHealing from "../images/energy_healing.webp";

const HomePage = () => {
  return (
    <Box backgroundColor="#FFEFD5" py={4} minHeight="100vh">
      <Container>
        <Navigation />

        {/* Logo Section
        <Grid2
          item
          xs={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: "20px" }}>
            <img
              src="/logolera.JPG"
              alt="LERAVYN Logo"
              style={{ height: "150px" }}
            />
          </div>
        </Grid2> */}

        {/* Main Section */}
        <Grid2
          container
          spacing={1}
          style={{
            justifyContent: "center",
            marginTop: "60px",
            marginBottom: "60px",
          }}
        >
          {/* <Grid2 item xs={12}>
            <Typography variant="h3" align="center" gutterBottom>
              Your Journey To Wholeness Begins Here
            </Typography>
            <Typography variant="h6" align="center">
              Welcome to your journey of Kundalini, Energy Activation, and Yoga.
              Let's transform together.
            </Typography>
          </Grid2> */}

          {/* Energy Healing Image with Text */}
          <Grid2
            item
            xs={12}
            style={{ position: "relative", marginTop: "20px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={EnergyHealing}
                alt="Energy Healing"
                style={{
                  maxWidth: "100%",
                  maxHeight: "500px",
                  objectFit: "cover",
                  filter: "brightness(70%)",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  textAlign: "center",
                  color: "#ffffff",
                  padding: "0 20px",
                }}
              >
                <Typography variant="h4" gutterBottom>
                  Transform Your Energy
                </Typography>
                <Typography variant="h6">
                  Explore the transformative power of Energy Healing and find
                  your inner balance.
                </Typography>
              </div>
            </div>
          </Grid2>

          {/* Recharge Program Section */}
          <Grid2 item xs={12} style={{ marginTop: "40px" }}>
            <Typography variant="h4" align="center" gutterBottom>
              Welcome to a Transformative Journey of Self-Discovery and Healing
            </Typography>
            <Typography variant="h6" align="center" marginBottom="60px">
              In today&#39;s fast-paced and often overwhelming world, many of us
              feel disconnected from our true selves, overwhelmed by stress, and
              uncertain of our purpose. The constant pressure to meet external
              expectations, coupled with the distractions of modern life, can
              make it challenging to stay grounded and aligned with our core
              essence. This is where energy healing and personal development
              tools, like the Human Potential System, can make a profound
              difference.
            </Typography>
            <Typography variant="h4" align="center" gutterBottom>
              Why Do We Need Energy Healing and Self-Discovery Today?
            </Typography>
            <Typography variant="h6" align="center" gutterBottom>
              Stress and Overwhelm. The demands of modern life—whether from
              work, relationships, or societal pressures—can create mental,
              emotional, and physical stress. This constant strain not only
              affects our health but also dims our ability to connect with our
              inner purpose. Energy healing offers a way to release these
              accumulated tensions and restore balance to our bodies and minds.
            </Typography>
            <Typography variant="h4" align="center" gutterBottom>
              Disconnection from Our True Purpose
            </Typography>
            <Typography variant="h6" align="center" gutterBottom>
              Many people struggle with finding fulfillment because they’ve lost
              touch with their authentic selves. We are often conditioned to
              follow paths that are not in alignment with our natural talents
              and desires, leading to feelings of dissatisfaction. By uncovering
              your unique abilities and reconnecting with your higher purpose,
              you can make choices that lead to a more meaningful and joyful
              life.
            </Typography>
            <Grid2
              container
              justifyContent="center"
              style={{ marginTop: "20px" }}
            >
              <Button variant="contained" color="inherit">
                Learn More
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
};

export default HomePage;

import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Box } from "@mui/material";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  // Function to switch languages
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
      <Button
        variant={i18n.language === "en" ? "contained" : "outlined"}
        onClick={() => changeLanguage("en")}
        sx={{ marginRight: "10px" }}
      >
        English
      </Button>
      <Button
        variant={i18n.language === "ru" ? "contained" : "outlined"}
        onClick={() => changeLanguage("ru")}
      >
        Русский
      </Button>
    </Box>
  );
};

export default LanguageSwitcher;

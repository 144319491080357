import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Typography, Box, Button, Grid2 } from "@mui/material";
import Navigation from "../compponents/Navigation";
import { useTranslation } from "react-i18next";
import EgoBalance from "../images/ego_balance.jpg";
import Hfc1 from "../images/hfc_1.jpg";
import Hfc2 from "../images/hfc_2.jpg";
import Kundalini from "../images/kundalini.jpg";
import HumanPotential from "../images/human_potential.jpg";

const imageMap = {
  "ego_balance.jpg": EgoBalance,
  "hfc_1.jpg": Hfc1,
  "hfc_2.jpg": Hfc2,
  "kundalini.jpg": Kundalini,
  "human_potential.jpg": HumanPotential,
};


const ServicePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const services = t("serviceList", { returnObjects: true });
  const service = services && services.length > id ? services[id] : null;

  const handleBookNow = () => {
    navigate("/contact");
  }

  if (!service) {
    return (
      <Container>
        <Typography variant="h4">{t("services.notFound", "Service not found")}</Typography>
      </Container>
    );
  }

  return (
    <div>
      <Container>
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Navigation />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "20px",
              alignItems: "stretch",
              marginTop: "40px",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "40%" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={imageMap[service.image]}
                alt={t(`serviceList.${id}.title`)}
                style={{
                  width: "100%",
                  maxWidth: "600px",
                  height: "auto",
                  margin: "20px auto",
                  display: "block",
                  borderRadius: "8px",
                }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "60%" },
                padding: "20px",
              }}
            >
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                style={{ marginTop: "40px" }}
              >
                {t(`serviceList.${id}.title`)}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  marginTop: "20px",
                  marginBottom: "40px",
                  lineHeight: "1.6",
                  textAlign: "justify",
                }}
              >
                {t(`serviceList.${id}.fullDescription`)}
              </Typography>
              <Grid2 item xs={4} container justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: "5px"}}
                onClick={handleBookNow}
              >
                {t("buttons.bookNow", "Book Now")}
              </Button>
              </Grid2>

            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default ServicePage;

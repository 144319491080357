import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import ru from "./locales/ru.json";
import about_en from "./locales/about_en.json";
import about_ru from "./locales/about_ru.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: { ...en, ...about_en }
    },
    ru: {
      translation: { ...ru, ...about_ru }
    }
  },
  lng: "en", // Default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import React from "react";
import { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FaInstagramSquare } from "react-icons/fa";
import Navigation from "../compponents/Navigation";
//import axios from "axios";
//import Footer from "../compponents/Footer";

const ContactUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://leravyn-backend.onrender.com', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (data.success) {
        alert('Email sent successfully!');
        setFormData({ firstName: "", lastName: "", email: "", message: "" }); // Clear form
      } else {
        alert('Failed to send email. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Something went wrong. Please try again later.');
    }
  };

  return (
    <Box backgroundColor="#EFEBE9" py={4} minHeight="100vh">
       <Container maxWidth="lg">
      <Navigation />
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 4,
          py: 7,
          px: isMobile ? 2 : 10,
        }}
      >
        {/* Contact Information Section */}
        <Box
          sx={{
            flexBasis: isMobile ? "100%" : "40%",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Get in Touch
          </Typography>
          <Typography variant="body1" gutterBottom>
            If you are interested in 1:1 session, please contact me.
          </Typography>
          <Typography variant="body1" gutterBottom>
            valeria@leravyn.com
          </Typography>
          {/* <Typography variant="body1" gutterBottom>
            +33420907630
          </Typography> */}
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-start",
            }}
          >
            <FaInstagramSquare style={{ fontSize: 60, cursor: "pointer" }} />
          </Box>
        </Box>

        {/* Contact Form Section */}
        <Box
          sx={{
            flexBasis: isMobile ? "100%" : "60%",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Contact Us
          </Typography>
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  type="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Container>
    </Box>
  );
};

export default ContactUs;

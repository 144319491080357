import React from "react";
import { Container, Button, Grid2, Typography, Box } from "@mui/material";
import Navigation from "../compponents/Navigation";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EgoBalance from "../images/ego_balance.jpg";
import Hfc1 from "../images/hfc_1.jpg";
import Hfc2 from "../images/hfc_2.jpg";
import Kundalini from "../images/kundalini.jpg";
import HumanPotential from "../images/human_potential.jpg";

const imageMap = {
  "ego_balance.jpg": EgoBalance,
  "hfc_1.jpg": Hfc1,
  "hfc_2.jpg": Hfc2,
  "kundalini.jpg": Kundalini,
  "human_potential.jpg": HumanPotential,
};

const Services = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLearnMore = (index) => {
    navigate(`/service/${index}`);
  };
  const services = t("serviceList", { returnObjects: true });

  return (
    <Box backgroundColor="#D8BFD8" py={4} minHeight="100vh">
      <Container>
        <Navigation />
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          style={{ marginTop: "40px", fontFamily: "'Raleway', sans-serif" }}
        >
          {t("services.title")}
        </Typography>

        <Grid2
          container
          spacing={4}
          style={{ marginTop: "20px", marginBottom: "40px" }}
          justifyContent="center"
        >
          {services.map((service, index) => (
            <Grid2 item xs={12} sm={6} key={index}>
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  padding: "20px",
                  borderRadius: "8px",
                  textAlign: "center",
                  width: "400px",
                  height: "650px",
                }}
              >
                <img
                  src={imageMap[service.image]}
                  alt={service.title}
                  style={{
                    width: "400px",
                    height: "400px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    marginTop: "20px",
                    fontFamily: "'Raleway', sans-serif",
                  }}
                >
                  {service.title}
                </Typography>
                <Typography variant="body1" style={{ marginTop: "10px" }}>
                  {service.description}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "15px" }}
                  onClick={() => handleLearnMore(index)}
                >
                  {t("buttons.learnMore")}
                </Button>
              </div>
            </Grid2>
          ))}
        </Grid2>
      </Container>
      {/* <Footer /> */}
    </Box>
  );
};
export default Services;
